import React from 'react';
import { useQuery } from '@apollo/client';
import GET_CUSTOMER_ORDER from '~/queries/get-customer-orders';
import { isEmpty } from 'lodash';
import { getFormattedDate } from '~/utils/functions';
import Link from 'gatsby-link';
import { useState } from 'react';

const Orders = ({ authData }) => {
  let id = "";
  if (authData) {
    const {
      user: { id },
    } = authData;
  }

  // Get Cart Data.
  const { data, error } = useQuery(GET_CUSTOMER_ORDER, {
    variables: {
      id: id,
    },
  });

  if (isEmpty(data)) {
    return null;
  }

  const {
    customer: { orders },
  } = data;

  return (
    <div className="space-y-5">
      {!isEmpty(orders.edges) ? (
        orders.edges.map((order) => {
          return (
            <div className="bg-white rounded-lg px-5 md:px-10 py-8 ">
              <div className="space-y-4">
                <div className="text-orange font-bold">{order.node.status}</div>
                <div className="flex items-center justify-between font-medium">
                  <div>Order: #{order.node.orderKey}</div>
                  <div>Date: {getFormattedDate(order.node.date)}</div>
                </div>
                {order.node.lineItems.edges.map((item, i) => {
                  return (
                    <div
                      key={`product-${i}`}
                      className="py-5 border-t-[#EEEEEE] border-t flex flex-col sm:flex-row sm:items-center space-y-5 sm:space-y-0"
                    >
                      <div className="space-y-5 sm:space-y-0 sm:space-x-10 flex flex-col sm:flex-row sm:items-center sm:w-3/4">
                        <div className="w-20 h-20 rounded-[4px] shrink-0">
                          <img
                            src={item.node.product.node.image.sourceUrl}
                            className="w-full h-full object-contain object-center"
                            srcSet={item.node.product.node.image.srcSet}
                          />
                        </div>
                        <div className="space-y-2">
                          <div className="t-18 font-bold">{item.node.product.node.name}</div>
                          <div className="text-[14px]">{item.node.product.node.slug}</div>
                        </div>
                      </div>
                      <div className="w-1/4">
                        <div className="font-semibold">{/* product.price */}</div>
                      </div>
                    </div>
                  );
                })}

                <div className="py-5 border-t-[#EEEEEE] border-t flex items-center">
                  <div className="flex items-center w-3/4 text-right justify-end pr-10">Order total</div>
                  <div className="w-1/4">
                    <div className="font-semibold">{order.node.total}</div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="p-3">
          <h4 className="mb-3">No orders found</h4>
          <Link to="/">
            <button className="btn-outline-dark" style={{ fontSize: '12px', padding: '8px 12px' }}>
              Shop now
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Orders;
