import { gql } from "@apollo/client";
const GET_CUSTOMER_ORDER = gql`
    query GET_CUSTOMER_ORDER( $id: ID! ) {
        customer( id: $id ) {
            id
            orders {
                edges {
                    node {
	                    id
                        orderKey
                        date
                        status
                        total
                        customerNote
                        paymentMethodTitle
                        lineItems {
                            edges {
                                node {
                                    product {
                                        node{
                                            name
                                            id
                                            sku
                                            image {
                                                id
                                                sourceUrl
                                                srcSet
                                                altText
                                                title
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default GET_CUSTOMER_ORDER;

