import React from "react";
import { isEmpty } from "lodash";

const AccountDetails = ({ authData }) => {
  const { user } = authData;

  return (
    <div className="bg-white rounded-lg px-5 md:px-10 py-8 space-y-6 font-medium">
      {!isEmpty(user.firstName) ? (
        <div className="flex flex-col">
          <div className="text-green font-bold">First Name</div>
          <div>{user.firstName}</div>
        </div>
      ) : null}
      {!isEmpty(user.lastName) ? (
        <div className="flex flex-col">
          <div className="text-green font-bold">Last Name</div>
          <div>{user.lastName}</div>
        </div>
      ) : null}
      {!isEmpty(user.username) ? (
        <div className="flex flex-col">
          <div className="text-green font-bold">Username</div>
          <div>{user.username}</div>
        </div>
      ) : null}
      {!isEmpty(user.email) ? (
        <div className="flex flex-col">
          <div className="text-green font-bold">Email</div>
          <div>{user.email}</div>
        </div>
      ) : null}
    </div>
  );
};

export default AccountDetails;
